import React from 'react'
import { Image } from './Image'
import { Link } from './Link'

const AppStoreLink = (): JSX.Element => {
  const appStoreUrl =
    'https://apps.apple.com/us/app/energy-level-tracker/id1629457989'

  return (
    <Link href={appStoreUrl} target="_blank">
      <Image
        src={`../../assets/images/platforms/ios.svg`}
        alt="Get on Appstore"
      />
    </Link>
  )
}

export default AppStoreLink
