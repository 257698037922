import React from 'react'
import styled from 'styled-components'
import AppStoreLink from '../../components/AppStoreLink'
import AppleLogo from '../../components/AppleLogo'
import { ArticleContent } from '../../components/ArticleContent'
import { ArticleContentBlock } from '../../components/ArticleContentBlock'
import CaseStudyImpression from '../../components/CaseStudyImpression'
import GoogleLogo from '../../components/GoogleLogo'
import { Layout } from '../../components/Layout'
import { Logo } from '../../components/Logo'
import { LogoWrapper } from '../../components/LogoWrapper'
import PlayStoreLink from '../../components/PlayStoreLink'
import SEO from '../../components/SEO'

const EnergyLevelTracker = (): JSX.Element => (
  <Layout>
    <SEO
      title="Energy Level Tracker App Case Study"
      description="Case Study for the Energy Level Tracker App"
    />
    <CaseStudyImpression
      image={
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      }
      text="Energy Level Tracker Case Study"
    >
      <StoreContainer>
        <PlayStoreLink />
        <AppStoreLink />
      </StoreContainer>
    </CaseStudyImpression>
    <ArticleContentBlock
      siblings={
        <ArticleContentSibling header="Platforms">
          <ImageContainer>
            <GoogleLogoImage />
            <AppleLogo />
          </ImageContainer>
        </ArticleContentSibling>
      }
      header="About the project"
      text='"Energy Level Tracker" is a new personal wellness monitoring
          mobile app that allows users to monitor and optimise their energy
          levels throughout the day. It was developed by the Raven Code team
          using React Native and is available on both Google Play Store and
          Apple&#39;s App Store. The goal was to create a user-friendly
          experience that offers both a seamless and intuitive way to track and
          optimise energy levels.'
    />

    <ArticleContentBlock
      lightTheme
      header="The Approach"
      text="We took an Agile methodology approach throughout the Energy Level
            Tracker project, employing two-week sprints to ensure efficiency and
            iterative progress. Our solution consisted of the following:"
    >
      <CaseStudyContainer>
        <section>
          <h3>Development Stack</h3>
          <ul>
            <li>
              React Native: We chose this framework for developing the mobile
              app as it offers cross-platform compatibility and a rich ecosystem
              of libraries.
            </li>
            <li>
              Expo: We used Expo to streamline development and provide easy
              access to device features and APIs.
            </li>
            <li>
              TypeScript: We employed TypeScript to enhance code quality,
              maintainability, and developer productivity by providing static
              type checking.
            </li>
            <li>
              i18n: We integrated i18n from the start to enable seamless support
              for multiple languages.
            </li>
            <li>
              Jest: We leveraged Jest for unit testing to ensure the reliability
              and stability of the app.
            </li>
            <li>
              JIRA: We utilized JIRA as the project management tool for
              efficient task tracking and collaboration.
            </li>
            <li>
              Git and GitHub Actions: We used Git and GitHub Actions for version
              control and continuous integration, enabling smooth collaboration
              among team members.
            </li>
            <li>
              Visual Studio Code, Android Studio, Xcode, EAS, Fastlane: We used
              these development tools and environments to facilitate the coding
              process.
            </li>
          </ul>
        </section>
        <section>
          <h3>User-Centric Approach</h3>
          <p>
            To ensure a well-rounded user experience, we fleshed out user
            stories based on user personas for the Energy Level Tracker app.
            These stories defined the needs and expectations of the app&#39;s
            target users. We then designed a prototype user interface (UX) using
            Figma, allowing us to visualize and refine the app&#39;s usability
            and aesthetics before development began.
          </p>
        </section>
      </CaseStudyContainer>
    </ArticleContentBlock>
    <ArticleContentBlock
      header="Results"
      text="The Energy Level Tracker project spanned three phases:"
    >
      <section>
        <section>
          <h3>Phase 1: Requirements</h3>
          <p>
            During this phase, we focused on capturing user requirements through
            user personas. We defined epics, representing high-level user goals,
            such as &quot;A user wants to track their energy level so they can
            work out their peak times.&quot; These epics were then broken down
            into features with detailed acceptance criteria using the
            GIVEN/WHEN/THEN syntax. For example:
          </p>
          <blockquote>
            <strong>GIVEN</strong> the user has installed the app
            <br />
            <strong>AND</strong> has <em>high</em> energy
            <br />
            <strong>WHEN</strong> the user selects the <em>high</em> level
            <br />
            <strong>THEN</strong> the <em>high</em> level is persisted
          </blockquote>
          <p>Deliverables for this phase included:</p>
          <ul>
            <li>User Personas</li>
            <li>User Stories (Epics / Features / Acceptance Criteria)</li>
            <li>Prototype</li>
            <li>Logo & Icon for app</li>
          </ul>
          <p>
            The usage of JIRA and Figma greatly facilitated the efficient
            capturing and management of user requirements.
          </p>
        </section>
        <section>
          <h3>Phase 2: Development</h3>
          <p>
            During the development phase, we implemented the features defined in
            the user stories using React Native and Expo, employing TypeScript
            for enhanced development efficiency and code quality.
          </p>
          <p>Deliverables for this phase included:</p>
          <ul>
            <li>Build Pipeline (DevOps)</li>
            <li>Mobile App (Android &amp; iPhone)</li>
          </ul>
          <p>The frameworks and tools used in this phase were:</p>
          <ul>
            <li>React Native</li>
            <li>Expo</li>
            <li>TypeScript</li>
            <li>i18n</li>
            <li>Jest</li>
            <li>JIRA</li>
            <li>Git</li>
            <li>GitHub Actions</li>
            <li>Visual Studio Code</li>
            <li>Android Studio</li>
            <li>Xcode</li>
            <li>EAS</li>
            <li>Fastlane</li>
          </ul>
        </section>
        <section>
          <h3>Phase 3: Deployment</h3>
          <p>
            In the final phase, we successfully deployed the Energy Level
            Tracker mobile app to the app stores, making it available for
            download on both Apple&#39;s App Store and Google&#39;s Play Store
            for iPhone and Android users, respectively. Additionally, we created
            a microsite to provide additional information about the app and
            promote its features.
          </p>
        </section>
      </section>
    </ArticleContentBlock>
    <ArticleContentBlock
      lightTheme
      header="About Raven Code"
      text={`We are a reliable and experienced software development company who can
        bring your mobile app idea to life. With our expertise in React Native,
        TypeScript, Agile methodologies and user-centric design, we are equipped
        to deliver exceptional mobile app solutions. Whether you need a complex
        mobile application or a simple yet effective app like the Energy Level
        Tracker, we have the skills and experience to meet your requirements.
        Our team will work closely with you to understand your vision and
        translate it into a user-friendly and high-performing mobile app. Let us
        take care of the technical complexities while you focus on your business
        goals. Contact us today at${' '}
        <a href="mailto:hello@ravencode.co">hello@ravencode.co</a> to discuss
        your next mobile app project. Empower your business with our expertise
        in React Native development and unlock the full potential of the mobile
        app market. Together, we can create an exceptional app experience that
        resonates with your target audience and drives your business forward.`}
    />
  </Layout>
)

export default EnergyLevelTracker

export const CaseStudyContainer = styled.section``

export const ImageContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const StoreContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
`

const GoogleLogoImage = styled(GoogleLogo)`
  margin-right: 1em !important;
`

const ArticleContentSibling = styled(ArticleContent)`
  padding-bottom: 0;
`
