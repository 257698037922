import React from 'react'
import styled from 'styled-components'
import LogoImage from '../assets/images/logos/Apple_logo.svg'

interface AppleLogoProps {
  className?: string
}

const AppleLogo = ({ className }: AppleLogoProps): JSX.Element => (
  <LogoContainer>
    <LogoImage fill="#fff" />
  </LogoContainer>
)

export default AppleLogo

export const Logo = styled(LogoImage)`
  width: 100%;
  height: auto;
`

export const LogoContainer = styled.div`
  display: inline-block;
  background-size: contain;
  max-width: 32px;
  max-height: 32px;
  padding: 1px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`
