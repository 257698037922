import React, { ReactElement } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import media from 'styled-media-query'
import FullContainer from './FullContainer'

interface CaseStudyImpressionProps extends JSX.ElementChildrenAttribute {
  text: string
  image?: ReactElement
}

export const CaseStudyImpression = ({
  text,
  children,
  image,
}: CaseStudyImpressionProps): JSX.Element => {
  const Image = () => <>{image}</>

  return (
    <StyledContainer>
      <Content>
        <Row>
          <Col xs={12} lg={3}>
            {Boolean(image) && <Image />}
          </Col>
          <Col xs={12} lg={9}>
            <Col xs={12}>
              <Header>
                <Text>{text}</Text>
              </Header>
            </Col>
            <ChildrenContainer xs={12}>{children}</ChildrenContainer>
          </Col>
        </Row>
      </Content>
    </StyledContainer>
  )
}

export default CaseStudyImpression

const StyledContainer = styled(FullContainer)`
  background: ${({ theme }) => theme.colors.impression};
  padding: 30px 0;
  ${media.lessThan('medium')`
    padding: 20px 0;
`}
`

const Text = styled.p`
  color: black;
  font-size: 2em;
  padding: 0;
  margin: 0 auto;
  max-width: 1100px;
  line-height: 1.6em;
  text-transform: uppercase;
`

const Content = styled.article`
  padding-top: 0;
  margin: 0 10vw;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const ChildrenContainer = styled(Col)`
  margin-top: 1em;
`
