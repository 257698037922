import React from 'react'
import { Image } from './Image'
import { Link } from './Link'

const PlayStoreLink = (): JSX.Element => {
  const playStoreUrl =
    'https://play.google.com/store/apps/details?id=co.ravencode.energyleveltracker'
  return (
    <Link href={playStoreUrl} target="_blank">
      <Image
        src={`../../assets/images/platforms/google-play-badge.png`}
        alt="Get on Google Play"
      />
    </Link>
  )
}

export default PlayStoreLink
