import React from 'react'
import { LogoImage } from './Image'

interface GoogleLogoProps {
  className?: string
}

const GoogleLogo = ({ className }: GoogleLogoProps): JSX.Element => (
  <LogoImage
    src={`../../assets/images/logos/Android_Robot.svg`}
    alt="Android Robot"
    className={className}
  />
)

export default GoogleLogo
